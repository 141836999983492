//styles
import "../../styles.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

//redux
import { useSelector } from "react-redux";
import { selectUser } from "state/slices/authSlice";

//payment components
import CreditCard from "./paymentMethods/CreditCard";
import Paypal from "./paymentMethods/Paypal";
import JCC from "./paymentMethods/JCC";
import Stripe from "./paymentMethods/Stripe";
import Viva from "./paymentMethods/Viva";
import CashPayment from "./paymentMethods/CashPayment";
import CardPayment from "./paymentMethods/CardPayment";

const addOrderToUser = async (userId, userOrderData) => {
  console.log("ANDY 1", { userId, userOrderData });
  // const url = `http://localhost:3000/addOrder/${userId}`;
  const url = `/api/users/addOrder/${userId}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userOrderData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Failed to add order:", error);
    throw error; // You may want to handle this error more gracefully in a production app
  }
};
const addOrderToOrders = async (orderOrderData) => {
  const url = `/api/orders/addOrder`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderOrderData),
    });

    if (!response.ok) {
      throw new Error("Failed to submit order: " + response.statusText);
    }

    const responseData = await response.json();
    console.log("Order submitted successfully:", responseData);
    return responseData;
  } catch (error) {
    console.error("Error submitting order:", error);
    alert("Error submitting order: " + error.message);
  }
};

const SelectedPaymentMethod = ({
  methodValue,
  orderAmount,
  deliveryMethod,
  buyersName,
  promoCode,
  discountAmount,
  setLoading,
}) => {
  const navigate = useNavigate();

  console.log("ANDY buyersName", buyersName);

  const store = useSelector((state) => state.store); // can maybe move these useSelectors into onSubmit function
  const { title, company, id, _id } = store?.store;
  const user = useSelector(selectUser) ?? null;
  const order = useSelector((state) => state.cart.items);
  const [loyaltyStampsAmount, setLoyaltyStampsAmount] = useState(0);

  useEffect(() => {
    let totalStamps = 0;
    order.forEach((item) => {
      if (item?.payload?.item?.addLoyaltyStamp) totalStamps += item?.quantity;
    });

    setLoyaltyStampsAmount(totalStamps);
  }, []);

  async function onSubmit(data) {
    let paymentSuccess = true; // This needs to be based on Payment method response
    let orderId = dayjs().format("DD-MM-YYYY_HH:mm:ss");
    console.log("ANDY Submitted Payment Success", {
      data,
      orderId,
    });
    setLoading(true);

    if (paymentSuccess) {
      // POST orderId=${`${orderId}-${table}-${orderAmount}`} to orders collection
      const userOrderData = {
        company,
        storeTitle: title,
        storeId: id,
        storeDBId: _id,
        order,
        orderId: `${orderId}-${store?.table}-${orderAmount}`,
        orderAmount,
        loyaltyStampsAdded: loyaltyStampsAmount,
        table: store?.table,
        deliveryMethod,
        paymentMethod: methodValue,
        promoCode,
        discountAmount,
        timestamp: orderId,
      };

      console.log("ANDY endpoint => userOrderData", {
        company,
        storeTitle: title,
        storeId: id,
        storeDBId: _id,
        order,
        orderId: `${orderId}-${store?.table}-${orderAmount}`,
        orderAmount,
        loyaltyStampsAdded: loyaltyStampsAmount,
        table: store?.table,
        deliveryMethod,
        paymentMethod: methodValue,
        promoCode,
        discountAmount,
        timestamp: orderId,
      });

      console.log("ANDY endpoint => orders", {
        user: {
          DBId: user?._id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          dob: user?.dob,
          address: user?.address,
          registrationDate: user?.registrationDate,
        },
        buyersName,
        company,
        storeTitle: title,
        storeId: id,
        storeDBId: _id,
        order,
        orderId: `${orderId}-${store?.table}-${orderAmount}`,
        orderAmount,
        table: store?.table,
        deliveryMethod,
        paymentMethod: methodValue,
        promoCode,
        discountAmount,
        timestamp: orderId,
      });

      const orderOrderData = {
        user: {
          DBId: user?._id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          dob: user?.dob,
          address: user?.address,
          registrationDate: user?.registrationDate,
        },
        buyersName,
        company,
        storeTitle: title,
        storeId: id,
        storeDBId: _id,
        order,
        orderId: `${orderId}-${store?.table}-${orderAmount}`,
        orderAmount,
        orderStatus: 0, // 0 = incomplete, 1 = completed, 2 = cancelled, 3 = other?. Should always be 0 here.
        table: store?.table,
        deliveryMethod,
        paymentMethod: methodValue,
        promoCode,
        discountAmount,
        timestamp: orderId,
      };

      // event.preventDefault();
      try {
        const addedOrderToUser = await addOrderToUser(
          user ? user?._id : "662eba495ed677999edb0e3c", //Anonymous user/Not logged in
          userOrderData
        );
        const addedOrderToOrders = await addOrderToOrders(orderOrderData);
        console.log("Order added successfully to users:", addedOrderToUser);
        console.log("Order added successfully to orders:", addedOrderToOrders);
        // Here you can handle redirection or clear form, show success message, etc.
        if (addedOrderToOrders) {
          navigate(
            `/store/order-confirmation?orderId=${`${orderId}-${store?.table}-${orderAmount}`}&deliveryMethod=${deliveryMethod}`,
            {
              state: {
                methodValue,
                orderAmount,
                deliveryMethod,
                buyersName,
                promoCode,
                store,
                loyaltyStampsAmount,
                discountAmount,
              },
            }
            //pass state here to tell order-confirmation page that user has come from checkout page. If user hasn't come from checkout page and reloads
            // order-confirmation page then that tells user-confirmation page to fetch the order data from backend
          );
        }
      } catch (error) {
        console.error("Error adding order:", error);
        // Here you might want to show error feedback to the user
      }
      setLoading(false);
    }
  }
  switch (methodValue) {
    case 0:
      return (
        <CreditCard
          orderAmount={orderAmount}
          onSubmit={(data) => onSubmit(data)}
        />
      );
      break;
    case 1:
      return <Paypal orderAmount={orderAmount} />;
      break;
    case 2:
      return <div>JCC Form</div>;
      break;
    case 3:
      return <Stripe orderAmount={orderAmount} onSubmit={() => onSubmit()} />;
      break;
    case 4:
      return <Viva orderAmount={orderAmount} />;
    case 99:
      return (
        <CashPayment
          orderAmount={orderAmount}
          onSubmit={(data) => onSubmit(data)}
        />
      );
    case 100:
      return (
        <CardPayment
          orderAmount={orderAmount}
          onSubmit={(data) => onSubmit(data)}
        />
      );
      break;
    default:
      <div>Payment currently unavailable</div>;
  }
};

const PaymentMethods = ({
  paymentMethods,
  selectedPaymentMethod,
  orderAmount,
  deliveryMethod,
  buyersName,
  promoCode = { promoCode },
  discountAmount,
}) => {
  const [loading, setLoading] = useState(false);

  console.log("ANDY all", paymentMethods);
  let paymentMethod = paymentMethods?.find(
    (method) => method?.value === selectedPaymentMethod
  );

  console.log("ANDY paymentMethod", paymentMethod);

  return (
    <div>
      {/* <div>Value: {paymentMethod?.value}</div>
      <div>Method: {paymentMethod?.method}</div>
      <div>Bank Number: {paymentMethod?.storeBankDetails?.bankNumber}</div>
      <div>
        Account Number: {paymentMethod?.storeBankDetails?.accountNumber}
      </div> */}
      <SelectedPaymentMethod
        methodValue={paymentMethod?.value}
        orderAmount={orderAmount}
        deliveryMethod={deliveryMethod}
        buyersName={buyersName}
        promoCode={promoCode}
        discountAmount={discountAmount}
        setLoading={(e) => setLoading(e)}
      />
      {loading && (
        <LoadingModal>
          <LoadingIcon />
        </LoadingModal>
      )}
    </div>
  );
};

const Payments = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  paymentMethods,
  orderAmount,
  deliveryMethod,
  buyersName,
  promoCode,
  discountAmount,
}) => {
  console.log("ANDY payments", {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentMethods,
    orderAmount,
    deliveryMethod,
    buyersName,
    promoCode,
    discountAmount,
  });
  return (
    <PaymentsWrapper>
      Payment:
      <select
        value={selectedPaymentMethod}
        onChange={(e) => setSelectedPaymentMethod(Number(e.target.value))}
      >
        {paymentMethods?.map((method) => (
          <option key={method?.value + method?.method} value={method?.value}>
            {method?.method}
          </option>
        ))}
      </select>
      <PaymentMethods
        paymentMethods={paymentMethods}
        selectedPaymentMethod={selectedPaymentMethod}
        orderAmount={orderAmount}
        deliveryMethod={deliveryMethod}
        buyersName={buyersName}
        promoCode={promoCode}
        discountAmount={discountAmount}
      />
    </PaymentsWrapper>
  );
};

export default Payments;

const PaymentsWrapper = styled.div`
  margin: 6px 0px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
  padding: 12px;
`;

const LoadingModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000080;
`;
const LoadingIcon = styled.div`
  width: 48px;
  height: 40px;
  margin-top: 30px;
  display: inline-block;
  position: relative;
  background: #333333;
  border-radius: 15% 15% 35% 35%;

  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 45px;
    top: 8px;
    border: 4px solid #333333;
    width: 16px;
    height: 20px;
    border-radius: 0 4px 4px 0;
  }
  &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 10px;
    color: #333333;
    top: -15px;
    left: 11px;
    box-sizing: border-box;
    animation: animloader 1s ease infinite;
  }

  @keyframes animloader {
    0% {
      box-shadow: 2px 0px rgba(0, 0, 0, 0), 12px 0px rgba(0, 0, 0, 0.3),
        20px 0px rgba(0, 0, 0, 0);
    }
    50% {
      box-shadow: 2px -5px rgba(0, 0, 0, 0.5), 12px -3px rgba(0, 0, 0, 0.5),
        20px -2px rgba(0, 0, 0, 0.6);
    }
    100% {
      box-shadow: 2px -8px rgba(0, 0, 0, 0), 12px -5px rgba(0, 0, 0, 0),
        20px -5px rgba(0, 0, 0, 0);
    }
  }
`;
