import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../styles.css";
import styled from "styled-components";
import {
  selectUser,
  selectIsAuthenticated,
} from "../../state/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { scrollLock } from "helpers/scrollLock";
import { getStoreData } from "state/slices/storeSlice";

const Header = ({ layout, logo, title, subtitle, children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isUserAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser) ?? {};
  const { store, table } = useSelector((state) => state.store);
  const navigate = useNavigate();

  // const { accountType, firstName, storeName } = user || {};
  const { accountType, firstName, storeName } =
    isUserAuthenticated && user.value
      ? user.value
      : isUserAuthenticated && user
      ? user
      : {};

  useEffect(() => {
    scrollLock(isMenuOpen);

    return () => scrollLock(false);
  }, [isMenuOpen]);

  console.log("ANDY session", {
    accountType,
    firstName,
    storeName,
    isUserAuthenticated,
    user,
  });

  return (
    <>
      <HeaderContainer
        className={"header-container " + layout?.header?.container}
      >
        {/* {logo.startsWith("<svg") ? <LogoSvg>{logo}</LogoSvg> : <LogoImg src={logo} />} */}
        <LogoImg
          src={logo}
          alt="logo"
          className={"header-logo " + layout?.header?.logo}
          onClick={() => navigate(`/store?id=${store?.id}&table=${table}`)}
          style={{ cursor: "pointer" }}
        />
        <HeaderTextContainer
          className={"header-textContainer " + layout?.header?.textContainer}
        >
          <HeaderTitle className={"header-title " + layout?.header?.title}>
            {title}
          </HeaderTitle>
          <HeaderSubtitle
            className={"header-subtitle " + layout?.header?.subtitle}
          >
            {subtitle}
          </HeaderSubtitle>
        </HeaderTextContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isMenuOpen ? (
            <div
              onClick={() => setIsMenuOpen(false)}
              style={{
                "font-size": "54px",
                position: "absolute",
                top: "5px",
                zIndex: 102,
              }}
            >
              ×
            </div>
          ) : (
            <div
              className="burger"
              onClick={() => setIsMenuOpen(true)}
              style={{
                height: "40px",
                display: "grid",
                alignItems: "center",
              }}
            >
              <div
                className="burger-line"
                style={{
                  height: "4px",
                  background: "black",
                  width: "30px",
                }}
              ></div>
              <div
                className="burger-line"
                style={{
                  height: "4px",
                  background: "black",
                  width: "30px",
                }}
              ></div>
              <div
                className="burger-line"
                style={{
                  height: "4px",
                  background: "black",
                  width: "30px",
                }}
              ></div>
            </div>
          )}
        </div>
        {isMenuOpen && (
          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              width: "80vw",
              maxWidth: "500px",
              height: "calc(100vh - 96px)",
              background: "white",
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
              padding: "48px 12px",
              zIndex: 101,
              display: "grid",
              justifyContent: "left",
              textAlign: "left",
              gap: "20px",
            }}
          >
            <SignInButton
              to={{
                pathname:
                  accountType === "User"
                    ? "/account"
                    : accountType === "Store"
                    ? "/portal/overview"
                    : "/login",
              }}
            >
              {accountType === "User"
                ? firstName
                : accountType === "Store"
                ? storeName
                : "Login"}
            </SignInButton>
            <Link>test</Link>
          </div>
        )}
      </HeaderContainer>
      {children}
    </>
  );
};

export default Header;

//
//
//
//
//
//

//Header styles
const HeaderContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 75px 1fr 75px;
  min-height: 75px;
  text-align: center;
  align-items: center;
  justify-items: center;
`;
const LogoImg = styled.img`
  position: relative;
  width: 75px;
`;
const LogoSvg = styled.div``;
const HeaderTextContainer = styled.div``;
const HeaderTitle = styled.div`
  font-size: 24px;
  text-wrap: nowrap;
  margin-bottom: 6px;
`;
const HeaderSubtitle = styled.div`
  font-size: 16px;
`;

const SignInButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: calc(100% - 28px);
  height: 50px;
  bottom: 50px;
  left: 12px;
  border: 2px solid black;
  border-radius: 3px;
  text-decoration: none;
  color: black;
`;
