// import { useState, useEffect, useRef } from "react";
// import ProgressBar from "components/general/ProgressBar";
// import "../../styles.css";
// import styled from "styled-components";

// const Promotions = ({ promotions, layout }) => {
//   const [activePromo, setActivePromo] = useState(1);
//   const [promoAmount, setPromoAmount] = useState(0);
//   const autoScrollContainerRef = useRef(null);
//   const scrollContainer = autoScrollContainerRef.current;
//   const currentDate = new Date().toISOString();
//   const livePromotions = promotions?.filter(
//     (promo) => promo?.endDate > currentDate
//   );

//   function handleShowPrevPromo() {
//     if (scrollContainer) {
//       if (activePromo !== 1) {
//         scrollContainer.scrollTo({
//           left: scrollContainer.scrollLeft - scrollContainer.clientWidth,
//           behavior: "smooth",
//         });
//         setActivePromo(activePromo - 1);
//       } else {
//         scrollContainer.scrollTo({
//           left:
//             scrollContainer.scrollLeft +
//             scrollContainer.clientWidth * livePromotions?.length,
//           behavior: "smooth",
//         });
//         setActivePromo(livePromotions?.length);
//       }
//     }
//   }

//   function handleShowNextPromo() {
//     if (scrollContainer) {
//       if (activePromo < livePromotions?.length) {
//         scrollContainer.scrollTo({
//           left: scrollContainer.scrollLeft + scrollContainer.clientWidth,
//           behavior: "smooth",
//         });
//         setActivePromo(activePromo + 1);
//       } else {
//         scrollContainer.scrollTo({
//           left:
//             scrollContainer.scrollLeft -
//             scrollContainer.clientWidth * livePromotions?.length,
//           behavior: "smooth",
//         });
//         setActivePromo(1);
//       }
//     }
//   }

//   useEffect(() => {
//     let initialPromo = 1;
//     let scrollContainer = autoScrollContainerRef.current;

//     const intervalId = setInterval(() => {
//       if (initialPromo < livePromotions?.length) {
//         handleShowNextPromo();
//         initialPromo++;
//       } else {
//         if (scrollContainer) {
//           scrollContainer.scrollTo({
//             left:
//               scrollContainer.scrollLeft -
//               scrollContainer.clientWidth * livePromotions?.length,
//             behavior: "smooth",
//           });
//           initialPromo = 1;
//         }
//       }

//       return () => {
//         scrollContainer = null;
//       };
//     }, 10000);

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, [livePromotions, handleShowNextPromo]);

//   console.log("ANDY asdasd", { promotions, livePromotions });
//   return (
//     <>
//       <PromotionsContainer
//         className={"promotions-container " + layout?.promotions?.container}
//         promoamount={livePromotions?.length}
//         ref={autoScrollContainerRef}
//       >
//         {livePromotions.map((promotion, i) => {
//           // endDate
//           // promoCode
//           // startDate

//           return (
//             <Promotion
//               key={
//                 i + promotion?._id + promotion?.name + promotion?.description
//               }
//               className={
//                 "promotions-promotion " + layout?.promotions?.promotion
//               }
//               backgroundcolour={promotion?.backgroundColor}
//               backgroundimage={promotion?.backgroundImage}
//             >
//               <PromotionTitle
//                 className={"promotions-title " + layout?.promotions?.title}
//               >
//                 {promotion?.name}
//               </PromotionTitle>
//               <PromotionInfo
//                 className={"promotion-info " + layout?.promotions?.info}
//               >
//                 {promotion?.description}
//               </PromotionInfo>
//               <ProgressBar />
//             </Promotion>
//           );
//         })}
//       </PromotionsContainer>
//     </>
//   );
// };

// export default Promotions;

// const PromotionsContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(
//     ${({ promoamount }) => promoamount && promoamount},
//     100%
//   );
//   width: 100%;
//   height: 150px;
//   scroll-snap-type: x mandatory;
//   overflow-x: scroll;
//   /* width */
//   &::-webkit-scrollbar {
//     width: 0px;
//     height: 0px;
//   }
//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }
//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: #888;
//   }
//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: #555;
//   }
// `;
// const Promotion = styled.div`
//   position: relative;
//   background-image: url(${({ backgroundimage }) =>
//     backgroundimage && backgroundimage});
//   background: ${({ backgroundcolour, backgroundimage }) =>
//     backgroundcolour && !backgroundimage && backgroundcolour};
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;

//   width: 100%;
//   height: 100%;

//   display: grid;
//   //   align-items: center;
//   justify-content: center;
//   text-align: center;
//   scroll-snap-align: center;
// `;
// const PromotionTitle = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: flex-end;
//   margin-bottom: 12px;

//   font-size: 24px;
// `;
// const PromotionInfo = styled.div`
//   font-size: 12px;
// `;

import { useState, useEffect, useRef, useCallback } from "react";
// import ProgressBar from "components/general/ProgressBar";
import "../../styles.css";
import styled from "styled-components";

const Promotions = ({ promotions, layout }) => {
  const [activePromo, setActivePromo] = useState(0);
  const autoScrollContainerRef = useRef(null);
  const currentDate = new Date().toISOString();

  const livePromotions = promotions?.filter(
    (promo) => currentDate >= promo?.startDate && currentDate <= promo?.endDate
  );

  const handleShowNextPromo = useCallback(() => {
    setActivePromo((prev) => (prev + 1 < livePromotions.length ? prev + 1 : 0));
  }, [livePromotions.length]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleShowNextPromo();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [handleShowNextPromo]);

  useEffect(() => {
    const scrollContainer = autoScrollContainerRef.current;
    if (scrollContainer && livePromotions.length > 0) {
      scrollContainer.scrollTo({
        left: activePromo * scrollContainer.clientWidth,
        behavior: "smooth",
      });
    }
  }, [activePromo, livePromotions.length]);

  const handleShowPrevPromo = () => {
    setActivePromo((prev) =>
      prev - 1 >= 0 ? prev - 1 : livePromotions.length - 1
    );
  };

  return (
    <PromotionsContainer
      className={`promotions-container ${layout?.promotions?.container}`}
      promoamount={livePromotions?.length}
      ref={autoScrollContainerRef}
    >
      {livePromotions.map((promotion, index) => (
        <Promotion
          key={`${promotion?._id}-${index}`}
          className={`promotions-promotion ${layout?.promotions?.promotion}`}
          backgroundcolour={promotion?.backgroundColor}
          backgroundimage={promotion?.backgroundImage}
        >
          <PromotionTitle
            className={`promotions-title ${layout?.promotions?.title}`}
          >
            {promotion?.name}
          </PromotionTitle>
          <PromotionInfo
            className={`promotion-info ${layout?.promotions?.info}`}
          >
            {promotion?.description}
          </PromotionInfo>
          {/* <ProgressBar /> */}
        </Promotion>
      ))}
    </PromotionsContainer>
  );
};

export default Promotions;

const PromotionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ promoamount }) => promoamount && promoamount},
    100%
  );
  width: 100%;
  height: 150px;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

const Promotion = styled.div`
  position: relative;
  background-image: ${({ backgroundimage }) =>
    backgroundimage ? `url(${backgroundimage})` : "none"};
  background-color: ${({ backgroundcolour, backgroundimage }) =>
    backgroundcolour && !backgroundimage ? backgroundcolour : "transparent"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  height: 100%;

  display: grid;
  justify-content: center;
  text-align: center;
  scroll-snap-align: center;
`;

const PromotionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 12px;

  font-size: 24px;
`;

const PromotionInfo = styled.div`
  font-size: 12px;
`;
