import React, { useState } from "react";
import dayjs from "dayjs";
const CashPayment = ({ orderAmount, onSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit("Cash Payment");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>€{orderAmount}</div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default CashPayment;
