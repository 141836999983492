import React, { useEffect } from "react";
import styled from "styled-components";
// Hooks

import Hero from "./home/Hero";
import Explore from "./home/Explore";
import Expertise from "./home/Expertise";
import PricingBlock from "./home/PricingBlock";
import useWidth from "hooks/width.hook";

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
`;

const HeaderLogo = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="/images/logo.png"
        alt="logo"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  );
};

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderLogo />
    </HeaderContainer>
  );
};

const Home = React.memo(() => {
  const width = useWidth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Hero />
      <Explore width={width} />
      <Expertise />
      <PricingBlock />
    </>
  );
});

export default Home;
