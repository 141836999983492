import React, { useEffect, useState, useRef } from "react";
import "../../styles.css";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../../state/slices/cartSlice";
import Close from "components/general/Close";
import { scrollLock } from "helpers/scrollLock";

const AddToCartQtyContainer2 = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  justify-content: center;
  gap: 8px;
  background: lightgreen;
  grid-row: 5;
  grid-column: 1 / 3;
  border-radius: 5px;
`;

const ArrowDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  padding: 8px;
  color: ${({ disabled }) => (disabled ? "#ccc" : "#333")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const QuantityDisplay2 = styled.div`
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  text-align: center;
  min-width: 50px;
  background: lightgreen;
`;

const AddToCartQtyComponent = ({ handleAddToCart, item }) => {
  const [quantity, setQuantity] = useState(0);
  const maxQuantity = item?.quantityAvailable || 99;

  const increaseQuantity = () => {
    setQuantity((prev) => Math.min(prev + 1, maxQuantity));
  };

  const decreaseQuantity = () => {
    setQuantity((prev) => Math.max(prev - 1, 0));
  };

  return (
    <AddToCartQtyContainer2 onClick={(e) => e.stopPropagation()}>
      {/* Left Decrease Div */}
      <ArrowDiv onClick={decreaseQuantity} disabled={quantity === 0}>
        &lt;
      </ArrowDiv>

      {/* Middle Quantity Display */}
      <QuantityDisplay2 onClick={() => handleAddToCart(quantity)}>
        {quantity}
      </QuantityDisplay2>

      {/* Right Increase Div */}
      <ArrowDiv onClick={increaseQuantity} disabled={quantity === maxQuantity}>
        &gt;
      </ArrowDiv>
    </AddToCartQtyContainer2>
  );
};

const Categories = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  layout,
}) => {
  return (
    <CategoriesContainer
      className={
        "menu-categories-container " + layout?.menu?.categories?.container
      }
      categoryAmount={["All", ...categories]?.length}
    >
      {["All", ...categories].map((category, i) => {
        return (
          <Category
            key={i + category}
            className={
              "menu-categories-category " + layout?.menu?.categories?.category
            }
            onClick={() => setSelectedCategory(category)}
            isActive={category === selectedCategory}
          >
            {category}
          </Category>
        );
      })}
    </CategoriesContainer>
  );
};

const AddToCartQty = ({
  item,
  itemExtras,
  layout,
  setItemModal,
  isItemModal = false,
}) => {
  const [buttonStatus, setButtonStatus] = useState("idle"); // 'idle', 'progress', or 'ready'
  const [itemQty, setItemQty] = useState(0);
  const [quantityOrderError, setQuantityOrderError] = useState(false);

  const dispatch = useDispatch();

  // handle add to cart tick animation
  useEffect(() => {
    let timer;
    if (buttonStatus === "progress") {
      const svg = document.getElementById("check");
      svg.classList.add("progress");
      timer = setTimeout(() => {
        svg.classList.toggle("progress");
        svg.classList.toggle("ready");
        setButtonStatus("ready");
      }, 500);
      setTimeout(() => {
        setButtonStatus("idle");
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [buttonStatus]);

  const handleAddToCart = () => {
    if (itemQty > 0) {
      // dispatch(addItem({ id: item?.id, payload: item, quantity: itemQty }));
      dispatch(
        addItem({
          id: item?.id,
          payload: { item, itemExtras },
          quantity: itemQty,
        })
      ); // payload is an object, take extras too
      setButtonStatus("progress");
    } else {
      setQuantityOrderError(true);
    }
  };

  return (
    <>
      <QtySelectorContainer
        className={
          "menu-addToCart-buttonQty " + layout?.menu?.addToCart?.buttonQty
        }
      >
        {isItemModal && (
          // <select
          //   name="quantity-select"
          //   id="qty-select"
          //   onChange={(e) => setItemQty(Number(e.target.value))}
          // >
          //   {[...Array(10).keys()].map((qty) => (
          //     <option key={qty} value={qty}>
          //       {qty}
          //     </option>
          //   ))}
          // </select>
          <>
            <div onClick={() => itemQty > 0 && setItemQty(itemQty - 1)}>-</div>
            <div>{itemQty}</div>
            <div onClick={() => setItemQty(itemQty + 1)}>+</div>
          </>
        )}
      </QtySelectorContainer>
      <AddToCartQtyContainer
        className={
          "menu-addToCart-container " + layout?.menu?.addToCart?.container
        }
        onClick={(e) => {
          e.stopPropagation();
          setItemModal ? setItemModal() : handleAddToCart();
        }}
        itemQty={itemQty}
        isItemModal={isItemModal}
      >
        {/* <div
          className={
            "menu-addToCart-buttonText " + layout?.menu?.addToCart?.buttonText
          }
          style={{ padding: "12px", cursor: "pointer" }}
          onClick={() => handleAddToCart()}
        > */}
        {buttonStatus === "idle" ? (
          <span style={{ fontSize: "18px" }}>
            {isItemModal ? "Add" : "Select"}
          </span>
        ) : (
          <svg
            id="check"
            className={buttonStatus}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsSlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 100 100"
            xmlSpace="preserve"
          >
            <circle
              id="AddedCircle"
              cx="50"
              cy="50"
              r="46"
              fill="transparent"
            />
            <polyline
              id="AddedTick"
              points="25,55 45,70 75,33"
              fill="transparent"
            />
          </svg>
        )}
        {/* </div> */}
      </AddToCartQtyContainer>
      {/* // <AddToCartQtyComponent /> */}
    </>
  );
};

const MenuItem = ({ item, index, layout, cartValue }) => {
  const [itemExtras, setItemExtras] = useState(null);
  const [itemModal, setItemModal] = useState(false);

  useEffect(() => {
    scrollLock(itemModal);
    return () => scrollLock(false);
  }, [itemModal]);

  return (
    <>
      <MenuItemContainer
        key={`${index}_${item?.name}`}
        className={"menuItems-item " + layout?.menu?.menuItems?.item}
        onClick={() => setItemModal(true)}
        image={item?.image}
      >
        <MenuItemImage
          className={
            "menuItems-itemImage " + layout?.menu?.menuItems?.itemImage
          }
          image={item?.image}
        />
        <MenuItemName
          className={"menuItems-itemName " + layout?.menu?.menuItems?.itemName}
        >
          {item?.name}
        </MenuItemName>
        <MenuItemDescription
          className={
            "menuItems-itemDescription " +
            layout?.menu?.menuItems?.itemDescription
          }
        >
          {item?.description}
        </MenuItemDescription>
        <MenuItemPrice
          className={
            "menuItems-itemPrice " + layout?.menu?.menuItems?.itemPrice
          }
        >
          €{item?.price}
        </MenuItemPrice>
        {/* <MenuItemImage
          className={
            "menuItems-itemImage " + layout?.menu?.menuItems?.itemImage
          }
          image={item?.image}
        /> */}
        {/* <MenuExtrasContainer
          className={
            "menuItems-extrasContainer " +
            layout?.menu?.menuItems?.extrasContainer
          }
        >
          {item?.extras?.map((extra, i) => {
            return (
              <div
                key={`${i}_${extra?.option}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
                className={
                  "menuItems-extraItemContainer " +
                  layout?.menu?.menuItems?.extraItemContainer
                }
                onClick={(e) => e.stopPropagation()}
              >
                <MenuExtrasLabel
                  className={
                    "menuItems-extraItemLabel " +
                    layout?.menu?.menuItems?.extraItemLabel
                  }
                  htmlFor={extra?.option}
                >
                  {extra?.option}
                </MenuExtrasLabel>
                <MenuExtrasSelect
                  className={
                    "menuItems-extraItemSelect " +
                    layout?.menu?.menuItems?.extraItemSelect
                  }
                  name={extra?.option}
                  id={extra?.option}
                  onChange={(e) =>
                    setItemExtras((prevState) => ({
                      ...prevState,
                      [extra?.option]: e.target.value,
                    }))
                  }
                >
                  {extra?.type?.map((type, i) => (
                    <MenuExtrasOption
                      key={`${i}_${extra?.option}_${type?.text}`}
                      value={[type?.text, type?.cost.toFixed(2)]}
                      className={
                        "menuItems-extraItemOption " +
                        layout?.menu?.menuItems?.extraItemOption
                      }
                    >
                      {type?.text}{" "}
                      {type?.cost > 0 && (
                        <span>(€{type?.cost.toFixed(2)})</span>
                      )}
                    </MenuExtrasOption>
                  ))}
                </MenuExtrasSelect>
              </div>
            );
          })}
        </MenuExtrasContainer> */}
        <AddToCartQty
          item={item}
          itemExtras={itemExtras}
          layout={layout}
          setItemModal={() => setItemModal(true)}
        />
      </MenuItemContainer>
      {itemModal && (
        <ItemModalWrapper>
          <ItemModalContainer cartValue={cartValue}>
            <Close onClick={() => setItemModal(false)} />
            {item?.image && (
              <div>
                <img
                  src={item?.image}
                  style={{
                    "object-fit": "cover",
                    width: "100%",
                    height: "50vh",
                    "mask-image":
                      "linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, transparent 100%)",
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "grid",
                "grid-template-columns": "70% 30%",
                gap: "12px 0",
                padding: "10px",
              }}
            >
              <div
                style={{
                  "grid-column": "1 / 3",
                  "font-size": "20px",
                  "font-weight": "bold",
                }}
              >
                {item?.name}
              </div>
              <div>{item?.description}</div>
              <div style={{ textAlign: "right" }}>€{item?.price}</div>

              <MenuExtrasContainer
                className={
                  "menuItems-extrasContainer " +
                  layout?.menu?.menuItems?.extrasContainer
                }
                isItemModal
              >
                {item?.extras?.map((extra, i) => {
                  return (
                    <div
                      key={`${i}_${extra?.option}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                      }}
                      className={
                        "menuItems-extraItemContainer " +
                        layout?.menu?.menuItems?.extraItemContainer
                      }
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MenuExtrasLabel
                        className={
                          "menuItems-extraItemLabel " +
                          layout?.menu?.menuItems?.extraItemLabel
                        }
                        htmlFor={extra?.option}
                      >
                        {extra?.option}
                      </MenuExtrasLabel>
                      <MenuExtrasSelect
                        className={
                          "menuItems-extraItemSelect " +
                          layout?.menu?.menuItems?.extraItemSelect
                        }
                        name={extra?.option}
                        id={extra?.option}
                        onChange={(e) =>
                          setItemExtras((prevState) => ({
                            ...prevState,
                            [extra?.option]: e.target.value,
                          }))
                        }
                        isItemModal
                      >
                        {extra?.type?.map((type, i) => (
                          <MenuExtrasOption
                            key={`${i}_${extra?.option}_${type?.text}`}
                            value={[type?.text, type?.cost.toFixed(2)]}
                            className={
                              "menuItems-extraItemOption " +
                              layout?.menu?.menuItems?.extraItemOption
                            }
                          >
                            {type?.text}{" "}
                            {type?.cost > 0 && (
                              <span>(€{type?.cost.toFixed(2)})</span>
                            )}
                          </MenuExtrasOption>
                        ))}
                      </MenuExtrasSelect>
                    </div>
                  );
                })}
              </MenuExtrasContainer>
              <AddToCartQty
                item={item}
                itemExtras={itemExtras}
                layout={layout}
                isItemModal
              />
            </div>
          </ItemModalContainer>
        </ItemModalWrapper>
      )}
    </>
  );
};

const MenuItems = ({ content, layout }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  let categories = content?.menu?.categories || [];
  const { items: cartValue } = useSelector((state) => state.cart);

  const categoryRefs = useRef({});

  // Initialize refs for categories
  useEffect(() => {
    categoryRefs.current = categories.reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
    }, {});
  }, [categories]);

  // Scroll on category click
  useEffect(() => {
    if (selectedCategory === "All") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else if (categoryRefs.current[selectedCategory]) {
      categoryRefs.current[selectedCategory].current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedCategory, categoryRefs]);

  console.log("ANDY cart", cartValue);

  return (
    <>
      <Categories
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={(e) => setSelectedCategory(e)}
        layout={layout}
      />
      {categories?.map((category, i) => {
        const items = content?.menu?.items?.[category];
        return (
          <MenuContainer
            key={`${i}_${category}`}
            className={
              "menuItems-menuContainer " +
              layout?.menu?.menuItems?.menuContainer
            }
            ref={categoryRefs.current[category]}
          >
            <MenuCategoryTitle
              className={"menuItems-title " + layout?.menu?.menuItems?.title}
            >
              {category}
            </MenuCategoryTitle>
            <MenuItemsContainer
              className={
                "menuItems-itemsContainer " +
                layout?.menu?.menuItems?.itemsContainer
              }
            >
              {items?.map((item, index) => (
                <MenuItem
                  key={index + item?.name + item?.price}
                  item={item}
                  index={index}
                  layout={layout}
                  cartValue={cartValue}
                />
              ))}
            </MenuItemsContainer>
          </MenuContainer>
        );
      })}
    </>
  );
};

export default MenuItems;

//
//
//
//
//
//
//

//Menu styles
const MenuWrapper = styled.div`
  display: grid;
  // grid-template-columns: 1fr 1fr;
`;
const MenuContainer = styled.div`
  // outline: 1px solid red;
`;
const MenuCategoryTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 18px 0;
`;
const MenuItemsContainer = styled.div`
  display: grid;
  // grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;
const MenuItemContainer = styled.div`
  display: ${({ image }) => (image ? "grid" : "block")};
  // grid-template-columns: auto 100px;
  grid-template-columns: auto 1fr;
  grid-template-rows: 24px auto auto auto;
  gap: 0 12px;
  // width: calc(100% - 48px);
  // margin: 0 12px;
  padding: 12px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
const MenuItemName = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;
const MenuItemDescription = styled.div`
  margin-bottom: 12px;
`;
const MenuItemPrice = styled.div`
  display: flex;
  align-items: end;
  justify-content: right;
  grid-column: 2;
  font-weight: bold;
`;
const MenuItemImage = styled.div`
  ${({ image }) =>
    image
      ? `
      background-image: url(${image});
      // background-color: rgba(0, 0, 0, 0.1);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      width: 100px;
      height: 100px;

      grid-row: 1 / 4;

      border-radius: 3px;
    `
      : `display: none;`}
`;
const MenuExtrasContainer = styled.div`
  display: grid;
  // grid-row: 3;
  grid-row: 3 / 5;
  ${({ isItemModal }) => isItemModal && "grid-column: 1 / 3;"}
`;
const MenuExtrasLabel = styled.label`
  text-transform: capitalize;
  margin: 8px 16px 8px 0;
`;
const MenuExtrasSelect = styled.select`
  width: 50%;
  margin: ${({ isItemModal }) => (isItemModal ? "0 0 16px" : "0 16px 16px 0")};
`;
const MenuExtrasOption = styled.option``;

const QtySelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / 3;
  font-weight: bold;
  gap: 20px;

  & > div:first-child,
  & > div:last-child {
    display: flex;
    background: lightgrey;
    border: 1px solid #80808082;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    justify-content: center;
    font-size: 33px;

    cursor: pointer;

    // &:hover {
    //   background: lightgreen;
    // }
  }
  & > div:nth-child(2) {
    width: 30px;
    text-align: center;
  }
`;
const AddToCartQtyContainer = styled.div`
  // display: grid;
  // grid-template-columns: 60px 20px;
  // grid-row: 4;
  // grid-column: 2;
  // grid-gap: 5px;
  background: ${({ itemQty, isItemModal }) =>
    isItemModal && itemQty === 0 ? "lightgrey" : "lightgreen"};
  border-radius: 3px;
  // width: 82px;
  // padding-right: 18px;
  margin-top: 12px;
  padding: 12px;
  text-align: center;
  z-index: 10;

  grid-row: 6;
  grid-column: 1 / 3;
  width: auto;
  justify-content: center;
  gap: 10px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    > select {
      height: 20px;
    }
  }
`;

const ItemModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  /* transform: translateY(0); */
  width: 100vw;
  height: 100vh;
  background: #f7f7f7;
  z-index: 25;
  // overflow: scroll;
`;
const ItemModalContainer = styled.div`
  overflow: scroll;
  height: ${({ cartValue }) =>
    cartValue.length > 0 ? "calc(100vh - 70px)" : "100vh"};
`;
//Categories
const CategoriesContainer = styled.div`
  position: sticky;
  top: 0px;
  background: white;
  display: inline-flex;
  gap: 10px;
  width: calc(100vw - 40px);
  height: 50px;
  border-top: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
  margin: 12px 0 0;
  padding: 0 12px;
  z-index: 20;

  display: grid;
  grid-template-columns: repeat(
    ${({ categoryAmount }) => categoryAmount && categoryAmount},
    auto
  );
  // grid-gap: 20px;
  align-items: center;
  text-align: center;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
`;
const Category = styled.div`
  padding: 14px 30px;
  width: max-content;
  // border: 1px solid lightgrey;
  border-radius: 25px;
  background: ${({ isActive }) => isActive && "rgba(0,0,0,0.1)"};
  cursor: pointer;
`;
