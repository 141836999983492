import React, { useState } from "react";

const CardPayment = ({ orderAmount, onSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit("Card Payment");
  };
  return (
    <form onSubmit={handleSubmit}>
      <div>€{orderAmount}</div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default CardPayment;
