export const stores = [
  {
    _id: {
      $oid: "6662e74182c711cd751ddb45",
    },
    id: 1,
    logo: "https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png",
    title: "MyCafe - Lefkosia!",
    company: "MyCafe",
    subtitle: "Sit, drink and enjoy!",
    content: {
      promotions: [
        {
          name: "Test",
          description: "Test",
          promoCode: "1324",
          startDate: {
            $date: "2024-09-11T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-09-22T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "rebeccapurple",
          _id: {
            $oid: "66563d2f2201bfbd9cb66fdf",
          },
        },
        {
          name: "Test 2",
          description: "Test 2",
          promoCode: "4321",
          startDate: {
            $date: "2024-06-24T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-07-01T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "brown",
          _id: {
            $oid: "66563d542201bfbd9cb66fed",
          },
        },
        {
          name: "Spring Saler",
          description: "Test 2",
          promoCode: "123123",
          startDate: {
            $date: "2024-05-14T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-05-21T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "",
          _id: {
            $oid: "66563f762201bfbd9cb67318",
          },
        },
        {
          name: "Spring Saler",
          description: "Test 2",
          promoCode: "123123",
          startDate: {
            $date: "2024-05-29T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-05-30T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "",
          _id: {
            $oid: "66563fc62201bfbd9cb67646",
          },
        },
        {
          name: "Test 2 Promo",
          description: "Promo description",
          promoCode: "Test123",
          startDate: {
            $date: "2024-05-07T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-05-08T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "",
          _id: {
            $oid: "665640632201bfbd9cb67fb7",
          },
        },
        {
          name: "Test 2 Promo",
          description: "Promo description",
          promoCode: "Test123",
          startDate: {
            $date: "2024-05-01T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-05-02T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "",
          _id: {
            $oid: "6656407f2201bfbd9cb682eb",
          },
        },
        {
          name: "Test 2 Promo",
          description: "Promo description",
          promoCode: "Test123",
          startDate: {
            $date: "2024-04-29T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-04-30T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "",
          _id: {
            $oid: "665643f62201bfbd9cb68c9a",
          },
        },
        {
          name: "Test 3",
          description: "Test 3",
          promoCode: "test3",
          startDate: {
            $date: "2024-06-01T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-06-01T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "lightblue",
          _id: {
            $oid: "66578463c2af0ced94073cad",
          },
        },
        {
          name: "Promo 1",
          description: "Promotional Test",
          promoCode: "prom0",
          startDate: {
            $date: "2024-06-08T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-06-09T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "steel",
          _id: {
            $oid: "665894b3b526fec8fe3bb920",
          },
        },
        {
          name: "The big one",
          description: "The big one promotion",
          promoCode: "B1G",
          startDate: {
            $date: "2024-07-03T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-07-29T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "red",
          _id: {
            $oid: "6660bd10fda297f46f65ed4d",
          },
        },
        {
          name: "Promo test 14/06",
          description: "New promo",
          promoCode: "June",
          startDate: {
            $date: "2024-06-14T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-06-23T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "red",
          _id: {
            $oid: "666d744b109ba041749dbc72",
          },
        },
        {
          name: "Test Promo 2",
          description: "Promo 2",
          promoCode: "123",
          startDate: {
            $date: "2024-06-15T00:00:00.000Z",
          },
          endDate: {
            $date: "2024-06-22T00:00:00.000Z",
          },
          backgroundImage: "",
          backgroundColor: "",
          _id: {
            $oid: "666d762d109ba041749dc808",
          },
        },
      ],
      menu: {
        categories: [
          "Hot Drinks",
          "Cold Drinks",
          "Cocktails",
          "Wine",
          "Exclusive",
        ],
        items: {
          "Hot Drinks": [
            {
              id: 1,
              name: "Caramel Macchiato",
              description: "Espresso, milk, cocoa powder",
              price: "5.99",
              image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ_qaLveraG81GyOcY6JaXNvgI18yPCVCGvgntCWfVag&s",
              extras: [
                {
                  option: "sugar",
                  type: [
                    {
                      text: "None",
                      value: 0,
                      cost: 0,
                    },
                    {
                      text: "1 teaspoon",
                      value: 1,
                      cost: 0.25,
                    },
                    {
                      text: "2 teaspoons",
                      value: 2,
                      cost: 0.5,
                    },
                  ],
                },
                {
                  option: "milk",
                  type: [
                    {
                      text: "Full",
                      value: 0,
                      cost: 0,
                    },
                    {
                      text: "Half",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "Oat",
                      value: 2,
                      cost: 0.25,
                    },
                    {
                      text: "Soya",
                      value: 3,
                      cost: 0.25,
                    },
                    {
                      text: "Almond",
                      value: 4,
                      cost: 0.25,
                    },
                    {
                      text: "None",
                      value: 5,
                      cost: 0,
                    },
                  ],
                },
              ],
              category: "Hot drinks",
              addLoyaltyStamp: true,
            },
            {
              id: 2,
              name: "Caffe Latte",
              description: "Espresso, milk",
              price: "4.99",
              image: "../images/caffe-latte.jpg",
              extras: [
                {
                  option: "sugar",
                  type: [
                    {
                      text: "1 teaspoon",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "2 teaspoons",
                      value: 2,
                      cost: 0,
                    },
                  ],
                },
                {
                  option: "milk",
                  type: [
                    {
                      text: "Full",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "Half",
                      value: 2,
                      cost: 0,
                    },
                    {
                      text: "Oat",
                      value: 3,
                      cost: 0,
                    },
                    {
                      text: "Soya",
                      value: 4,
                      cost: 0,
                    },
                    {
                      text: "Almond",
                      value: 5,
                      cost: 0,
                    },
                    {
                      text: "None",
                      value: 6,
                      cost: 0,
                    },
                  ],
                },
              ],
              category: "Hot drinks",
              addLoyaltyStamp: true,
            },
            {
              id: 3,
              name: "Caffe Mocha",
              description: "Espresso, milk, chocolate syrup",
              price: "6.99",
              image: "../images/caffe-mocha.jpg",
              extras: [
                {
                  option: "sugar",
                  type: [
                    {
                      text: "1 teaspoon",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "2 teaspoons",
                      value: 2,
                      cost: 0,
                    },
                  ],
                },
                {
                  option: "milk",
                  type: [
                    {
                      text: "Full",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "Half",
                      value: 2,
                      cost: 0,
                    },
                    {
                      text: "Oat",
                      value: 3,
                      cost: 0,
                    },
                    {
                      text: "Soya",
                      value: 4,
                      cost: 0,
                    },
                    {
                      text: "Almond",
                      value: 5,
                      cost: 0,
                    },
                    {
                      text: "None",
                      value: 6,
                      cost: 0,
                    },
                  ],
                },
              ],
              category: "Hot drinks",
              addLoyaltyStamp: true,
            },
          ],
          "Cold Drinks": [
            {
              id: 4,
              name: "Frappe",
              description: "Espresso, milk, whipped cream",
              price: "4.50",
              image: "https://example.com/frappe.jpg",
              extras: [
                {
                  option: "sugar",
                  type: [
                    {
                      text: "1 teaspoon",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "2 teaspoons",
                      value: 2,
                      cost: 0,
                    },
                  ],
                },
                {
                  option: "milk",
                  type: [
                    {
                      text: "Full",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "Half",
                      value: 2,
                      cost: 0,
                    },
                    {
                      text: "Oat",
                      value: 3,
                      cost: 0,
                    },
                    {
                      text: "Soy",
                      value: 4,
                      cost: 0,
                    },
                    {
                      text: "Almond",
                      value: 5,
                      cost: 0,
                    },
                    {
                      text: "None",
                      value: 6,
                      cost: 0,
                    },
                  ],
                },
                {
                  option: "syrup",
                  type: [
                    {
                      text: "Chocolate",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "Caramel",
                      value: 2,
                      cost: 0,
                    },
                    {
                      text: "Vanilla",
                      value: 3,
                      cost: 0,
                    },
                    {
                      text: "Hazelnut",
                      value: 4,
                      cost: 0,
                    },
                    {
                      text: "None",
                      value: 5,
                      cost: 0,
                    },
                  ],
                },
              ],
              category: "Cold drinks",
              addLoyaltyStamp: true,
            },
            {
              id: 5,
              name: "Iced Coffee",
              description: "Cold brewed coffee served over ice",
              price: "3.99",
              image: "https://example.com/iced-coffee.jpg",
              extras: [
                {
                  option: "sugar",
                  type: [
                    {
                      text: "1 teaspoon",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "2 teaspoons",
                      value: 2,
                      cost: 0,
                    },
                  ],
                },
                {
                  option: "milk",
                  type: [
                    {
                      text: "Full",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "Half",
                      value: 2,
                      cost: 0,
                    },
                    {
                      text: "Oat",
                      value: 3,
                      cost: 0,
                    },
                    {
                      text: "Soy",
                      value: 4,
                      cost: 0,
                    },
                    {
                      text: "Almond",
                      value: 5,
                      cost: 0,
                    },
                    {
                      text: "None",
                      value: 6,
                      cost: 0,
                    },
                  ],
                },
              ],
              category: "Cold drinks",
              addLoyaltyStamp: true,
            },
            {
              id: 6,
              name: "Iced Tea",
              description: "Chilled black tea served over ice",
              price: "3.50",
              image: "https://example.com/iced-tea.jpg",
              extras: [
                {
                  option: "sugar",
                  type: [
                    {
                      text: "1 teaspoon",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "2 teaspoons",
                      value: 2,
                      cost: 0,
                    },
                  ],
                },
                {
                  option: "lemon",
                  type: [
                    {
                      text: "Yes",
                      value: true,
                      cost: 0,
                    },
                    {
                      text: "No",
                      value: false,
                      cost: 0,
                    },
                  ],
                },
              ],
              category: "Cold drinks",
              addLoyaltyStamp: true,
            },
            {
              id: 7,
              name: "Smoothie",
              description: "Blended mixture of fruits and yogurt",
              price: "5.50",
              image: "https://example.com/smoothie.jpg",
              extras: [
                {
                  option: "fruit",
                  type: [
                    {
                      text: "Strawberry",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "Banana",
                      value: 2,
                      cost: 0,
                    },
                    {
                      text: "Mango",
                      value: 3,
                      cost: 0,
                    },
                    {
                      text: "Pineapple",
                      value: 4,
                      cost: 0,
                    },
                  ],
                },
                {
                  option: "yogurt",
                  type: [
                    {
                      text: "Regular",
                      value: 1,
                      cost: 0,
                    },
                    {
                      text: "Greek",
                      value: 2,
                      cost: 0,
                    },
                    {
                      text: "Coconut",
                      value: 3,
                      cost: 0,
                    },
                  ],
                },
              ],
              category: "Cold drinks",
              addLoyaltyStamp: true,
            },
          ],
          Cocktails: [
            {
              id: 8,
              name: "Margarita",
              description: "Tequila, triple sec, lime juice",
              price: "8.99",
              image: "https://example.com/margarita.jpg",
              category: "Cocktails",
              addLoyaltyStamp: true,
            },
            {
              id: 9,
              name: "Mojito",
              description: "White rum, lime juice, mint leaves, soda water",
              price: "7.99",
              image: "https://example.com/mojito.jpg",
              category: "Cocktails",
              addLoyaltyStamp: true,
            },
            {
              id: 10,
              name: "Cosmopolitan",
              description: "Vodka, triple sec, cranberry juice, lime juice",
              price: "9.99",
              image: "https://example.com/cosmopolitan.jpg",
              category: "Cocktails",
              addLoyaltyStamp: true,
            },
            {
              id: 11,
              name: "Pina Colada",
              description: "White rum, coconut cream, pineapple juice",
              price: "9.50",
              image: "https://example.com/pina-colada.jpg",
              category: "Cocktails",
              addLoyaltyStamp: true,
            },
          ],
          Wine: [
            {
              id: 12,
              name: "Chardonnay",
              description:
                "A dry white wine with flavors of apple, pear, and citrus.",
              price: "12.99",
              image: "https://example.com/chardonnay.jpg",
              category: "Wine",
              addLoyaltyStamp: true,
            },
            {
              id: 13,
              name: "Merlot",
              description:
                "A medium-bodied red wine with flavors of plum, cherry, and herbal notes.",
              price: "14.99",
              image: "https://example.com/merlot.jpg",
              category: "Wine",
              addLoyaltyStamp: true,
            },
            {
              id: 14,
              name: "Sauvignon Blanc",
              description:
                "A crisp white wine with flavors of green apple, lime, and tropical fruits.",
              price: "11.99",
              image: "https://example.com/sauvignon-blanc.jpg",
              category: "Wine",
              addLoyaltyStamp: true,
            },
            {
              id: 15,
              name: "Cabernet Sauvignon",
              description:
                "A full-bodied red wine with flavors of blackberry, cassis, and cedar.",
              price: "16.99",
              image: "https://example.com/cabernet-sauvignon.jpg",
              category: "Wine",
              addLoyaltyStamp: true,
            },
          ],
          Exclusive: [
            {
              id: 16,
              name: "Gold Rush Cocktail",
              description: "Bourbon, honey, lemon juice",
              price: "12.99",
              image: "https://example.com/gold-rush-cocktail.jpg",
              category: "Exclusive",
              addLoyaltyStamp: true,
            },
            {
              id: 17,
              name: "Truffle Pasta",
              description:
                "Pasta with truffle cream sauce, topped with shaved truffles",
              price: "16.99",
              image: "https://example.com/truffle-pasta.jpg",
              category: "Exclusive",
              addLoyaltyStamp: true,
            },
            {
              id: 18,
              name: "Wagyu Beef Burger",
              description:
                "A burger made with Wagyu beef, served with truffle fries",
              price: "22.99",
              image: "https://example.com/wagyu-burger.jpg",
              category: "Exclusive",
              addLoyaltyStamp: true,
            },
            {
              id: 19,
              name: "Champagne Tower",
              description:
                "A tower of champagne glasses filled with premium champagne",
              price: "89.99",
              image: "https://example.com/champagne-tower.jpg",
              category: "Exclusive",
              addLoyaltyStamp: true,
            },
          ],
        },
      },
    },
    deliveryMethods: [
      {
        value: 1,
        label: "Table Service",
        _id: {
          $oid: "66efe5f5035f9c0962009bfd",
        },
      },
      {
        value: 2,
        label: "Collection",
        _id: {
          $oid: "66efe5f5035f9c0962009bfe",
        },
      },
    ],
    paymentMethods: [
      {
        value: 0,
        method: "Credit Card",
        _id: {
          $oid: "66efe5f5035f9c0962009bff",
        },
      },
      {
        value: 99,
        method: "Cash",
        options: [],
        _id: {
          $oid: "66efe5f5035f9c0962009c00",
        },
      },
      {
        value: 3,
        method: "Stripe",
        _id: {
          $oid: "66efe5f5035f9c0962009c01",
        },
      },
      {
        value: 100,
        method: "Card at table",
        options: [],
        _id: {
          $oid: "66efe5f5035f9c0962009c02",
        },
      },
    ],
    layout: {
      background: "rgba(57,124,132,1)",
      typography: {
        fontFamily: "segoe ui",
        colors: {
          primary: "#ffffff",
          secondary: "#000000",
          tertiary: "#ffff00",
        },
        fontSize: {
          small: "12px",
          medium: "16px",
          large: "24px",
        },
      },
      header: {
        container: "",
        logo: "",
        textContainer: "",
        title: "",
        subtitle: "",
      },
      promotions: {
        container: "",
        promotion: "",
        title: "",
        info: "",
      },
      menu: {
        menuItems: {
          menuContainer: "",
          title: "",
          itemsContainer: "",
          item: "",
          itemName: "",
          itemPrice: "",
          itemDescription: "",
          itemImage: "",
          extrasContainer: "",
          extraItemContainer: "",
          extraItemLabel: "",
          extraItemSelect: "",
          extraItemOption: "",
        },
        categories: {
          container: "",
          category: "",
        },
        addToCart: {
          container: "",
          buttonText: "",
          buttonQty: "",
        },
      },
      cartItems: {},
      aiAssistant: {
        img: "",
        container: "",
      },
      cart: {
        container: "",
        closeButton: "",
        itemscontainer: "",
        item: "",
        itemQuantitySelect: "",
        itemQuantityOption: "",
        deleteItem: "",
        itemName: "",
        itemQuantity: "",
        itemCost: "",
        cartTableTotal: "",
        cartTable: "",
        cartTotal: "",
        continueButton: "",
      },
      payments: {
        deliveryMethodSelect: "",
        deliveryMethodOption: "",
      },
      _id: {
        $oid: "666419319d279aaca55861ea",
      },
    },
    css: {
      header: {
        "header-container": {
          color: "#000000",
          background: "red",
          "font-size": "",
        },
        "header-logo": {
          width: "",
          height: "",
        },
        "header-textContainer": {
          padding: "",
        },
        "header-title": {
          "font-weight": "",
          color: "",
        },
        "header-subtitle": {
          "font-weight": "",
          color: "",
        },
      },
      promotions: {
        "promotions-container": {
          display: "",
          padding: "",
        },
        "promotions-promotion": {
          margin: "",
          border: "",
        },
        "promotions-title": {
          "font-size": "",
          color: "",
        },
        "promotion-info": {
          "font-size": "",
          color: "",
        },
      },
      menuItems: {
        "menuItems-menuContainer": {
          background: "",
          padding: "",
        },
        "menuItems-title": {
          "font-size": "",
          color: "",
        },
        "menuItems-itemsContainer": {
          display: "",
        },
        "menuItems-item": {
          border: "",
          padding: "",
        },
        "menuItems-itemName": {
          "font-weight": "",
          color: "",
        },
        "menuItems-itemPrice": {
          "font-size": "",
          color: "",
        },
        "menuItems-itemDescription": {
          color: "",
        },
        "menuItems-itemImage": {
          width: "",
          height: "",
        },
        "menuItems-extrasContainer": {
          margin: "",
        },
        "menuItems-extraItemContainer": {
          padding: "",
        },
        "menuItems-extraItemLabel": {
          "font-size": "",
        },
        "menuItems-extraItemSelect": {
          padding: "",
          "border-radius": "",
        },
        "menuItems-extraItemOption": {
          color: "",
          background: "",
        },
      },
      menu: {
        "menu-categories-container": {
          display: "",
          "justify-content": "",
        },
        "menu-categories-category": {
          margin: "",
          color: "",
        },
        "menu-addToCart-container": {
          position: "",
          bottom: "",
        },
        "menu-addToCart-buttonText": {
          color: "",
          "font-weight": "",
        },
        "menu-addToCart-buttonQty": {
          "font-size": "",
          color: "",
        },
      },
      aiAssistant: {
        "aiAssistant-img": {
          width: "",
          "border-radius": "",
        },
        "aiAssistant-container": {
          padding: "",
          background: "",
        },
      },
      cart: {
        "cart-container": {
          width: "",
          background: "",
        },
        "cart-closeButton": {
          position: "",
          top: "",
          right: "",
        },
        "cart-itemscontainer": {
          padding: "",
        },
        "cart-item": {
          display: "",
          "border-bottom": "",
        },
        "cart-itemQuantitySelect": {
          border: "",
          "border-radius": "",
        },
        "cart-itemQuantityOption": {
          color: "",
          background: "",
        },
        "cart-deleteItem": {
          cursor: "",
          color: "",
        },
        "cart-itemName": {
          "font-weight": "",
          color: "",
        },
        "cart-itemQuantity": {
          "font-size": "",
        },
        "cart-itemCost": {
          "font-weight": "",
          color: "",
        },
        "cart-cartTableTotal": {
          "font-weight": "",
          color: "",
        },
        "cart-cartTable": {
          "font-size": "",
          "font-weight": "",
          color: "",
        },
        "cart-cartTotal": {
          "font-size": "",
          "font-weight": "",
          color: "",
        },
        "cart-continueButton": {
          cursor: "",
          color: "",
        },
      },
      payments: {
        "payments-deliveryMethodSelect": {
          width: "",
          height: "",
        },
        "payments-deliveryMethodOption": {
          width: "",
          height: "",
        },
      },
    },
    extras: {},
    storeAccounts: [
      {
        email: "nero_dali@hotmail.com",
        dbId: {
          $oid: "662d726ea9ad1ff01a7f6622",
        },
      },
    ],
    __v: 16,
    rewards: {
      loyaltyStamps: {
        stampsRequiredForReward: 12,
        rewardType: 0,
      },
    },
  },
];

export default stores;
