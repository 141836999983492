import { useEffect, useState } from "react";
import styled from "styled-components";

//flaticon
import preparingOrder from "../../assets/preparing.mp4";
import readyOrder from "../../assets/ready-alarm.mp4";
import readySound from "../../assets/audio/success-1.mp3";

const CollectionNotification = ({ orderId }) => {
  const [order, setOrder] = useState(null);
  const [orderStatus, setOrderStatus] = useState(false);

  useEffect(() => {
    const getOrderStatus = async (orderId) => {
      try {
        const response = await fetch(`/api/orders/getOrderById/${orderId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const order = await response.json();
        setOrder(order);
        console.log("ANDY fetch order", order);
        if (order.orderStatus === 1) {
          console.log("Order is ready for collection");
          setOrderStatus(true);
        } else {
          console.log("Order is NOT ready for collection");
        }
      } catch (err) {
        console.log("ANDY order fetch failed", err);
      }
    };

    const intervalId = setInterval(() => {
      if (!orderStatus) getOrderStatus(orderId);
    }, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [orderId, orderStatus]);

  useEffect(() => {
    if (orderStatus) {
      // // Vibrate for 500 milliseconds
      // navigator.vibrate(500);

      // Vibrate for 500ms, pause for 100ms, then vibrate for 500ms again
      // navigator.vibrate([500, 100, 500]);

      //vibrate for a total of 6 times with 1000ms intervals
      navigator.vibrate([
        ...Array.from({ length: 6 }).flatMap(() => [1000, 500]),
      ]);

      // add sound effect
      new Audio(readySound).play();
    }

    const videoPlayer = document.getElementById("video-player");

    setTimeout(() => {
      videoPlayer.play();
    }, 100);
  }, [orderStatus]);

  return (
    <CollectionNotificationWrapper className="collection-notification">
      <div id="video-container">
        <video
          id="video-player"
          autoplay
          loop
          muted
          playsInline
          key={orderStatus}
          src={orderStatus ? readyOrder : preparingOrder}
          type="video/mp4"
        >
          Your browser does not support the video tag.
        </video>
      </div>
      {orderStatus ? (
        <h3>{`${order?.buyersName}, YOUR ORDER IS READY FOR COLLECTION`}</h3>
      ) : (
        <h3>YOUR ORDER IS BEING PREPARED</h3>
      )}
    </CollectionNotificationWrapper>
  );
};

export default CollectionNotification;

const CollectionNotificationWrapper = styled.div`
  & #video-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & video {
    width: 50%;
  }

  & h3 {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
`;
