//styles
import "../../styles.css";

import { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setStoreData, setTable } from "../../state/slices/storeSlice";

//data
import mock_stores from "../../mock_server/stores";

//components
import Header from "./Header";
import Promotions from "./Promotions";
import MenuItems from "./MenuItems";
import AIAssistant from "./AIAssistant";
import CallAssistance from "./CallAssistance";
import Cart from "./Cart";

//helpers
// import customCss from "../helpers/customCss";

import {
  useSetSessionStorage,
  useGetSessionStorage,
} from "../../helpers/useStorageSession";
import Checkout from "../checkout";
import OrderConfirmation from "../order-confirmation";
import Login from "../login";
import UserAccount from "../account/UserAccount";
import Loading from "components/Loading";
import Footer from "./Footer";

const StoreMenu = () => {
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const getSessionStorage = useGetSessionStorage();
  const setSessionStorage = useSetSessionStorage();
  const dispatch = useDispatch();
  console.log("ANDY setStore", store);
  useEffect(() => {
    const id = urlParams.get("id");
    const table = urlParams.get("table");
    const storedStoreData = getSessionStorage("store");

    console.log("ANDY StoreMenu fetch 1", {
      id,
      storedStoreData,
      store,
      storedId: storedStoreData?.id,
      istrue: storedStoreData?.id == id,
    });

    if (
      (!id && storedStoreData) ||
      (storedStoreData && storedStoreData?.id == id)
      // false
    ) {
      setStore(storedStoreData);
      dispatch(setStoreData(storedStoreData));
      dispatch(setTable(table));
      setLoading(false);
    } else {
      const fetchStore = async () => {
        setLoading(true);
        try {
          const response = await fetch(`/api/stores/${id}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          console.log("ANDY StoreMenu fetch 2", { data });
          setStore(data);
          setSessionStorage("store", data);
          dispatch(setStoreData(data));
          dispatch(setTable(table));
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data: ", error);
          setLoading(false);
        }
      };
      fetchStore();
    }
  }, []);

  /* Use this if MongoDB is down to continue development and remove store condition from render()
    const {
      id,
      logo,
      title,
      subtitle,
      content,
      paymentMethods,
      layout,
      css,
      extras,
    } = mock_stores?.[0];
  */

  function customCssBuilder(cssData) {
    console.log("cssData", cssData);
    let cssString = "";

    for (let section in cssData) {
      const sectionData = cssData[section];

      for (let cssClass in sectionData) {
        cssString += `.${cssClass} {`;

        for (let property in sectionData[cssClass]) {
          const value = sectionData[cssClass][property];

          if (value) {
            cssString += `${property}: ${value};`;
          }
        }

        cssString += "} ";
      }
    }

    console.log("cssString", cssString);

    return cssString;
  }

  const cartItems = useSelector((state) => state.cart.items);

  console.log("ANDY store", store);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Orderly - {store?.title || "store"}</title>
            {/* <link rel="icon" type="image/x-icon" href="/images/favicon.ico" /> */}
            <link rel="canonical" href="http://orderlyhub.com" />
            {store?.css && Object.keys(store.css).length > 0 && (
              <style>{customCssBuilder(store?.css)}</style>
            )}
            {store?.layout?.typography?.fontFamily && (
              <style>{`body { font-family: '${store?.layout.typography.fontFamily}', "Helvetica", "Arial", sans-serif; }`}</style>
            )}
          </Helmet>
          <Header
            layout={store?.layout}
            logo={store?.logo}
            title={store?.title || ""}
            subtitle={store?.subtitle}
          />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {store?.content?.promotions && (
                    <Promotions
                      promotions={store?.content?.promotions}
                      layout={store?.layout}
                    />
                  )}
                  <MenuWrapper>
                    <MenuItems
                      content={store?.content}
                      layout={store?.layout}
                    />
                  </MenuWrapper>
                  <AIAssistant data={store} layout={store?.layout} />
                  <CallAssistance data={store} layout={store?.layout} />
                  {cartItems?.length > 0 && (
                    <Cart items={cartItems} layout={store?.layout} />
                  )}
                  <div>Unfair Pricing - Report button</div>
                  <div>Table Help button</div>
                </>
              }
            />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/order-confirmation" element={<OrderConfirmation />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
};

export default StoreMenu;

const StoreWrapper = styled.div`
  // background: pink;
  // font-family: "segoe ui", "Helvetica", "Arial", sans-serif;
`;
//Menu styles
const MenuWrapper = styled.div`
  display: grid;
  // grid-template-columns: 1fr 1fr;
`;
