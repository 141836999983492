import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setUser, login } from "../../state/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { setLocalStorageWithExpiry } from "helpers/localStorage";
import md5 from "md5";

const CreateAccountForm = (orderData) => {
  const [accountType, setAccountType] = useState("User");
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    verifyPassword: null,
    dob: null,
    // favouriteStore: { type: Schema.Types.ObjectId, ref: "Store" },
    // loyaltyStamps: [loyaltyStampSchema],
    accountType: accountType,
    // profileImageUrl: String,
    // phone: String,
    address: null,
    registrationDate: Date.now(),
    // lastLogin: Date,
    // isActive: { type: Boolean, default: true },
    // passwordResetToken: String,
    // passwordResetExpires: Date,
    securityQuestions: [
      // {
      //   question: String,
      //   answerHash: String,
      // },
    ],
    ...(orderData?.orderData && { orders: [{ ...orderData?.orderData }] }),
  });
  const [passwordValidity, setPasswordValidity] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check password validity when the password field is changed
    if (name === "password") {
      validatePassword(value);
    }
  };

  const validatePassword = (password) => {
    const validity = {
      minLength: password?.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordValidity(validity);
  };

  const isPasswordValid = () => {
    return Object.values(passwordValidity).every(Boolean);
  };

  async function createOrUpdateUser(userData) {
    try {
      const response = await fetch("/api/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...userData,
          password: md5(userData.password),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const updatedUser = await response.json();
      console.log("User created/updated successfully:", updatedUser);
      return updatedUser;
    } catch (error) {
      console.error("Error creating/updating user:", error);
    }
  }

  const handleSubmit = (e) => {
    setError(false);
    e.preventDefault();
    if (
      isPasswordValid() &&
      formData.password === formData.verifyPassword &&
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.password
    ) {
      createOrUpdateUser(formData).then((response) => {
        if (response) {
          // If authentication is successful, set user and login
          dispatch(setUser(response));
          dispatch(login());
          setLocalStorageWithExpiry("userSession", response);

          // Navigate to the portal for 'Store' users or back to the previous page for others
          if (response?.accountType === "Store") {
            navigate("/portal/overview");
          } else {
            navigate(-1);
          }
        } else {
          alert("Handle endpoint Error");
        }
      });
    } else {
      setError(true);
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      {/* <div>
        <label>Account Type:</label>
        <select
          name="accountType"
          value={accountType}
          onChange={(e) => {
            setAccountType(e.target.value);
            handleChange(e);
          }}
        >
          <option value="User">User</option>
          <option value="Store">Store</option>
        </select>
      </div> */}

      {accountType === "User" && (
        <>
          <div>
            {/* <label>Name:</label> */}
            <input
              type="text"
              placeholder="Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className={error && !formData.firstName ? "error" : ""}
            />
          </div>
          <div>
            {/* <label>Surname:</label> */}
            <input
              type="text"
              placeholder="Surname"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className={error && !formData.lastName ? "error" : ""}
            />
          </div>
        </>
      )}

      {/* {accountType === "Store" && (
        <>
          <div>
            <label>Store Name:</label>
            <input
              type="text"
              placeholder="Store Name"
              name="storeName"
              value={formData.storeName}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Address:</label>
            <input
              type="text"
              placeholder="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
        </>
      )} */}

      <div>
        {/* <label>Email:</label> */}
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          className={error && !formData.email ? "error" : ""}
        />
      </div>
      <div>
        {/* <label>Password:</label> */}
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          className={error && !formData.password ? "error" : ""}
        />
      </div>
      {formData?.password?.length > 0 && (
        <ul>
          <li style={{ color: passwordValidity.minLength ? "green" : "red" }}>
            At least 8 characters
          </li>
          <li style={{ color: passwordValidity.uppercase ? "green" : "red" }}>
            At least one uppercase letter
          </li>
          <li style={{ color: passwordValidity.lowercase ? "green" : "red" }}>
            At least one lowercase letter
          </li>
          <li style={{ color: passwordValidity.number ? "green" : "red" }}>
            At least one number
          </li>
          <li style={{ color: passwordValidity.specialChar ? "green" : "red" }}>
            At least one special character
          </li>
        </ul>
      )}
      <div>
        {/* <label>Repeat Password:</label> */}
        <input
          type="password"
          placeholder="Repeat Password"
          name="verifyPassword"
          value={formData.verifyPassword}
          onChange={handleChange}
          style={{
            ...(formData?.verifyPassword?.length > 0 && {
              outline: `1px solid ${
                formData.verifyPassword === formData.password ? "green" : "red"
              }`,
            }),
          }}
          className={error && !formData.verifyPassword ? "error" : ""}
        />
      </div>

      <button
        type="submit"
        disabled={
          !isPasswordValid() || formData.password !== formData.verifyPassword
        }
      >
        Create Account
      </button>
    </FormWrapper>
  );
};

export default CreateAccountForm;

const FormWrapper = styled.form`
  display: grid;
  align-items: center;
  justify-items: center;

  height: max-content;
  gap: 20px;
  margin-bottom: 50px;

  div {
    width: 100%;
  }

  input {
    width: 100%;
    height: 40px;

    &.error {
      outline: 2px solid red;
    }
  }

  button {
    height: 50px;
    width: 150px;
  }
`;
