import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setUser, login } from "../../state/slices/authSlice";
import { useNavigate, useLocation, Link } from "react-router-dom";
import md5 from "md5";

import CreateAccountForm from "./CreateAccountForm";
import { setLocalStorageWithExpiry } from "helpers/localStorage";

const Login = () => {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { orderData } = location.state || {};

  async function fetchUserByEmailAndPassword(email, password) {
    // const url = "https://your-domain.com/api/users/login"; // Adjust URL accordingly When page goes live

    try {
      const response = await fetch("/api/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password: md5(password) }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const user = await response.json();
      console.log("User fetched successfully:", user);
      alert("User fetched successfully:", user);
      return user;
    } catch (error) {
      console.error("Error fetching user:", error);
      return null;
    }
  }

  const handleLogin = async () => {
    if (credentials.email && credentials.password) {
      fetchUserByEmailAndPassword(credentials.email, credentials.password).then(
        (response) => {
          if (response) {
            // If authentication is successful, set user and login
            dispatch(setUser(response));
            dispatch(login());
            setLocalStorageWithExpiry("userSession", response);

            // Navigate to the portal for 'Store' users or back to the previous page for others
            if (response?.accountType === "Store") {
              navigate("/portal/overview");
            } else {
              navigate(-1);
            }
          } else {
            alert("Handle endpoint Error");
          }
        }
      );
    } else {
      // Handle login failure (e.g., show an error message)
      console.error("Login failed: email or password missing");
      alert("Login failed: email or password missing");
    }
  };

  return (
    <>
      {isLoginForm && !orderData ? (
        <div style={{ display: "grid" }}>
          <input
            type="text"
            placeholder="Email"
            value={credentials.email}
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
          />
          <input
            type="password"
            placeholder="Password"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
          />
          <button onClick={handleLogin}>Login</button>

          <div>Sign in with Facebook/Instagram/Gmail etc</div>
        </div>
      ) : (
        <CreateAccountForm orderData={orderData} />
      )}
      <div onClick={() => setIsLoginForm(!isLoginForm)}>
        {isLoginForm && !orderData ? "Create an account" : "Log in"}
      </div>
      <div style={{ marginTop: "10px" }}>
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
    </>
  );
};

export default Login;
