import { useEffect, useState } from "react";
import styled from "styled-components";

//styles
import "../../styles.css";

//redux
import { useSelector, useDispatch } from "react-redux";

const DeliveryMethod = ({
  layout,
  deliveryMethod,
  setDeliveryMethod,
  deliveryMethods,
}) => {
  return (
    <div>
      <div>Delivery Method: </div>
      <div>
        <select
          className={`payments-deliveryMethodSelect ${layout?.payments?.deliveryMethodSelect}`}
          value={deliveryMethod}
          onChange={(e) => setDeliveryMethod(Number(e.target.value))}
        >
          {deliveryMethods?.map((method) => (
            <option
              className={`payments-deliveryMethodOption ${layout?.payments?.deliveryMethodOption}`}
              key={method.label.replace(" ", "_")}
              value={method.value}
            >
              {method.label}
            </option>
          ))}
        </select>
        {deliveryMethod === 2 && (
          <div>
            Please collect your order when we notify you that your order is ready.
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryMethod;
