const Close = ({ onClick, position, styles }) => {
  return (
    <button
      onClick={onClick}
      aria-label="Close button"
      style={{
        position: position || "absolute",
        background: "none",
        border: "none",
        "font-size": "40px",
        width: "100%",
        "text-align": "right",
        zIndex: 20,
        ...(position === "relative" && {
          top: "-10px",
          right: "-10px",
        }),
        ...styles,
      }}
    >
      ×
    </button>
  );
};

export default Close;
