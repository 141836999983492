import { useState } from "react";
import "../../styles.css";
import styled from "styled-components";

import AIAssistantImage from "../../assets/bot.png";
import Close from "components/general/Close";

const AssistantChat = ({ setAiActive, layout }) => {
  return (
    <>
      <AiChatContainer
        className={
          "aiAssistant-container " + layout?.menu?.categories?.container
        }
      >
        <Close onClick={() => setAiActive(false)} />
      </AiChatContainer>
    </>
  );
};

const AIAssistant = ({ data, layout, showAssistance }) => {
  const [aiActive, setAiActive] = useState(false);

  function activateAssistant() {
    // alert(
    //   "When clicked, open text box to the left? With some height? Also allow voice to text. When submitted whatever recommendation is sent should be autoscrolled to. Add predefined options on initial load."
    // );
    console.log("ANDY store data", data);

    setAiActive(!aiActive);
  }
  return (
    <>
      <AssistantImg
        src={AIAssistantImage}
        alt="AI Assistant"
        onClick={() => activateAssistant()}
        // aiActive={aiActive}
        showAssistance={showAssistance}
        className={"aiAssistant-img " + layout?.aiAssistant?.img}
      />
      {showAssistance && aiActive && (
        <AssistantChat setAiActive={(e) => setAiActive(e)} layout={layout} />
      )}
      {/* <div>Beany = Coffee shop AI</div>
      <div>Spork = Restaurant shop AI</div>
      <div>
        Build Basic version of GPT assistant that will take store data object
        and answer questions based on that exact data.
      </div> */}
    </>
  );
};

export default AIAssistant;

//
//
//
//
//
//

const AssistantImg = styled.img`
  position: fixed;
  bottom: 15px;
  right: 75px;
  width: 20px;
  right: ${({ showAssistance }) => (showAssistance ? "75px" : "15px")};
  bottom: ${({ showAssistance }) => (showAssistance ? "15px" : "15px")};
  height: 20px;
  background: rgba(173, 216, 230, 1);
  opacity: ${({ showAssistance }) => (showAssistance ? 1 : 0)};
  border-radius: 100px;
  padding: 10px;
  cursor: pointer;
  z-index: 100;

  &:hover {
    opacity: 1;
  }

  transition: all 0.2s ease-in-out;
`;

const AiChatContainer = styled.div`
  position: fixed;
  bottom: 5px;
  left: 8px;
  width: calc(100vw - 16px);
  height: 75vh;
  background: #f7f7f7;
  z-index: 95;
  outline: 1px solid lightsteelblue;
`;
