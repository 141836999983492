export function scrollLock(shouldLock) {
  const html = document.getElementsByTagName("html")[0];

  if (shouldLock) {
    html.classList.add("lock-scroll");
  } else {
    html.classList.remove("lock-scroll");
  }
  return (): void => {
    html.classList.remove("lock-scroll");
  };
}
