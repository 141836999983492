import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../store/Header";
import CollectionNotification from "./CollectionNotification";
import { Item } from "components/checkout/Checkout";

import { clearCart } from "../../state/slices/cartSlice";
import { selectUser } from "state/slices/authSlice";

const Button = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      style={{ padding: "10px 20px", cursor: "pointer", margin: "5px" }}
    >
      {children}
    </button>
  );
};

const OrderConfirmation = () => {
  const [order, setOrder] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  console.log("ANDY url data", { urlParams, queryString });

  const orderId = urlParams.get("orderId");
  const deliveryMethod = urlParams.get("deliveryMethod");
  console.log("ANDY url params", { orderId, deliveryMethod });

  const user = useSelector(selectUser) ?? null;
  const { store } = useSelector((state) => state.store);
  const { items: cart } = useSelector((state) => state.cart);
  console.log("ANDY qweqweqweqwe cart", cart);
  // If cart is false, useEffect => /api/orders/getOrderById/${orderId}, and setCartItems(result)

  const getOrderStatus = async (orderId) => {
    try {
      console.log("ANDY qweqweqweqwe", orderId);
      const response = await fetch(`/api/orders/getOrderById/${orderId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const order = await response.json();
      console.log("ANDY ORDERORDER", order);
      setOrder(order);
      setCartItems(order?.order);
      return order;
    } catch (err) {
      console.log("ANDY order fetch failed", err);
      return null;
    }
  };

  useEffect(() => {
    if (cart.length === 0) {
      getOrderStatus(orderId);
    } else {
      setCartItems(cart);
    }

    setTimeout(() => {
      dispatch(clearCart());
    }, 3000);
  }, []);

  const totalItems = cartItems?.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  const totalDiscount = cartItems?.reduce((total, item) => {
    const itemPrice = parseFloat(item.payload.item.price);
    const quantity = item.quantity;
    return (
      // Math.round((total + itemPrice * quantity - order?.orderAmount) * 100) /
      // 100
      total + Number((itemPrice * quantity).toFixed(2))
    );
  }, 0);

  const navigateToRegistration = async () => {
    let currentOrderData = await getOrderStatus(orderId)
      .then((orderData) => {
        if (orderData) {
          navigate("/login", { state: { orderData } });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleDownloadReceipt = () => {
    window.print();
  };

  return (
    <>
      <div
        onClick={() => navigate(-2)}
        style={{ display: "inline-flex", alignItems: "center", gap: "10px" }}
      >
        <div>{"<"}</div>
        <h3>Order Confirmation</h3>
      </div>
      <div>Sit back and relax</div>
      <div>Your order is on its way!</div>

      <div>Order Number: {orderId}</div>
      <div>
        Order:
        {cartItems?.map((item, i) => (
          <Item key={i} item={item} i={i} />
        ))}
      </div>
      <div>Total Items: {totalItems}</div>
      <div>Discount: €{(totalDiscount - order?.orderAmount)?.toFixed(2)}</div>
      <div>Price: €{order?.orderAmount}</div>
      <div>
        {console.log("ANDY order?.deliveryMethod", order?.deliveryMethod)}
        {(deliveryMethod === "1" || order?.deliveryMethod === 1) && (
          <div>Table Service</div>
        )}
        {(deliveryMethod === "2" || order?.deliveryMethod === 2) && (
          <div>
            <CollectionNotification orderId={orderId} />
          </div>
        )}
      </div>
      <div>
        Should collect as much data about order, user and user journey to be
        able to supply partners with analytics data
      </div>

      {!user && (
        <div>
          <Button onClick={() => navigateToRegistration()}>
            Create Account
          </Button>
        </div>
      )}

      <div>
        <Button onClick={handleDownloadReceipt}>Download Receipt</Button>
      </div>
    </>
  );
};
export default OrderConfirmation;
