//styles
import "../../styles.css";

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { useSelector } from "react-redux";
import { selectUser } from "state/slices/authSlice";
//components
import Header from "../store/Header";
import Payments from "./Payments";
import DeliveryMethod from "./DeliveryMethod";

export const Item = ({ item, i, layout }) => {
  const id = item?.id;
  const quantity = item?.quantity;
  const itemDetails = item?.payload?.item;
  const itemExtras = item?.payload?.itemExtras;

  let itemExtrasTotal = 0;

  if (itemExtras) {
    Object.values(itemExtras)?.forEach((value) => {
      const [_type, cost] = value?.split(",");
      itemExtrasTotal += Number(cost);
    });
  }
  return (
    <ItemContainer
      key={i + id + itemDetails?.name}
      className={"cart-item " + layout?.cart?.item}
    >
      <div
        style={{
          marginBottom: "12px",
          fontWeight: "bold",
        }}
        className={"cart-itemName " + layout?.cart?.itemName}
      >
        {itemDetails?.name}
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end" }}
        className={"cart-itemQuantity " + layout?.cart?.itemQuantity}
      >
        x{quantity}
      </div>
      {itemExtras && (
        <div style={{ fontSize: "14px" }}>
          {Object.entries(itemExtras)?.map(([key, value], i) => {
            const [type, cost] = value?.split(",");
            return (
              <div key={i + key + value} style={{ margin: "6px 0 0" }}>
                {/* {`${key}: ${type} €${cost}`} */}
                {`${key}: ${type}`}
              </div>
            );
          })}
        </div>
      )}
      <div
        style={{
          gridColumn: "2 / 3",
          display: "grid",
          justifyContent: "flex-end",
          alignItems: "end",
          fontWeight: "bold",
        }}
        className={"cart-itemCost " + layout?.cart?.itemCost}
      >
        €
        {((Number(itemDetails?.price) + itemExtrasTotal) * quantity).toFixed(2)}
      </div>
    </ItemContainer>
  );
};

const Checkout = () => {
  const [deliveryMethod, setDeliveryMethod] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [buyersName, setBuyersName] = useState(null);
  const [discountAmount, setDiscountAmount] = useState("0.00");
  const [cartTotal, setCartTotal] = useState("0.00");

  const { items: cart } = useSelector((state) => state.cart);
  const { store } = useSelector((state) => state.store);
  const user = useSelector(selectUser);
  const { layout, deliveryMethods, paymentMethods } = store;
  const navigate = useNavigate();
  let location = useLocation();
  const { items } = location.state || [];

  const livePromos = [
    //will get this array from database
    { code: "123", discount: 2, type: "-" },
    { code: "000", discount: 1, type: "-" },
    { code: "free", discount: 100, type: "%" },
    { code: "10off", discount: 10, type: "%" },
    { code: "20off", discount: 20, type: "%" },
    { code: "50off", discount: 50, type: "%" },
    { code: "80off", discount: 80, type: "%" },
  ];

  console.log("ANDY data", {
    buyersName,
    promoCode,
    deliveryMethod,
    selectedPaymentMethod,
    items,
    cart,
    store,
  });
  console.log("ANDY state", { store, cart, selectedPaymentMethod, items });

  useEffect(() => {
    if (user?.firstName || user?.storeName) {
      setBuyersName(user?.firstName || user?.storeName);
    }
  }, []);

  useEffect(() => {
    // if (deliveryMethods?.length > 0) {
    setDeliveryMethod(deliveryMethods[0].value);
    // }
    // if (paymentMethods?.length > 0) {
    setSelectedPaymentMethod(paymentMethods[0].value);
    // }
  }, [deliveryMethods, paymentMethods]);

  useEffect(() => {
    let total = 0;

    cart?.forEach((item) => {
      const itemExtras = item?.payload?.itemExtras;
      let itemExtrasTotal = 0;
      if (itemExtras) {
        Object.values(itemExtras)?.forEach((value) => {
          const [_type, cost] = value?.split(",");
          itemExtrasTotal += Number(cost);
        });
      }

      total +=
        (Number(item?.payload?.item?.price) + itemExtrasTotal) * item?.quantity;
    });

    setCartTotal(total.toFixed(2));
  }, [cart]);

  useEffect(() => {
    if (!promoCode) {
      setDiscountAmount("0.00");
      return;
    }

    const validPromo = livePromos.find(
      (promo) => promo.code.toLowerCase() === promoCode.toLowerCase()
    );

    if (validPromo) {
      let discount = 0;
      if (validPromo.type === "-") {
        discount = validPromo.discount;
      } else if (validPromo.type === "%") {
        discount = (Number(cartTotal) * validPromo.discount) / 100;
      }
      // Ensure discount does not exceed cartTotal
      discount = Math.min(discount, Number(cartTotal));
      setDiscountAmount(discount.toFixed(2));
    } else {
      setDiscountAmount("0.00");
    }
  }, [promoCode, cartTotal, livePromos]);

  const finalTotal = (Number(cartTotal) - Number(discountAmount)).toFixed(2);
  console.log("ANDY buyersName", buyersName);
  return (
    <CheckoutWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
          padding: "12px 0",
        }}
      >
        <span
          onClick={() => navigate(-1)}
          style={{ fontSize: "24px", cursor: "pointer" }}
        >
          &larr;
        </span>
        <h3 style={{ fontSize: "18px" }}>Checkout</h3>
      </div>
      <div
        className="checkout-cart-items"
        style={{
          margin: "6px 0",
          borderRadius: "4px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 8px",
        }}
      >
        {items?.map((item, i) => (
          <Item key={i} item={item} i={i} layout={layout} />
        ))}
      </div>
      <CheckoutFormWrapper className="checkout-form">
        <OrderInputInfo>
          <div>
            <div>Name: </div>
            <input
              type="text"
              placeholder={buyersName || "Name"}
              // value={
              //   user ? user?.firstName || user?.storeName : buyersName || ""
              // }
              onChange={(e) => setBuyersName(e.target.value)}
            />
          </div>
          <DeliveryMethod
            layout={layout}
            deliveryMethod={deliveryMethod}
            setDeliveryMethod={setDeliveryMethod}
            deliveryMethods={deliveryMethods}
          />
          <div>
            <div>Promo Code: </div>
            <input
              type="text"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
          </div>
          <div>
            <div>Price breakdown: </div>
            <div style={{ fontWeight: "bold" }}>
              <div>Subtotal: €{cartTotal}</div>
              <div>Discount: -€{discountAmount}</div>
              <div>Total: €{finalTotal}</div>
            </div>
            {/* Implement logic to apply promotions and rewards */}
          </div>
        </OrderInputInfo>
        <Payments
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          paymentMethods={paymentMethods}
          orderAmount={finalTotal}
          deliveryMethod={deliveryMethod}
          buyersName={buyersName}
          promoCode={promoCode}
          discountAmount={discountAmount}
        />
      </CheckoutFormWrapper>
    </CheckoutWrapper>
  );
};

export default Checkout;

const CheckoutWrapper = styled.div`
  padding: 0 12px;
`;

const CheckoutFormWrapper = styled.div``;

const OrderInputInfo = styled.div`
  margin: 6px 0px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
  padding: 6px 12px;

  & > div {
    // display: flex;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px;
    margin: 12px 0;

    & > div {
      font-weight: bold;
    }
  }
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;

  padding: 12px;
  border-bottom: 1px solid lightgrey;
`;
