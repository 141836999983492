import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setUser, login } from "../../state/slices/authSlice";
import { useNavigate, useLocation, Link } from "react-router-dom";
import md5 from "md5";
import orderlyLogo from "../../assets/orderly_logo_v0.png";

import CreateAccountForm from "./CreateAccountForm";
import { setLocalStorageWithExpiry } from "helpers/localStorage";

const Login = () => {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { orderData } = location.state || {};

  async function fetchUserByEmailAndPassword(email, password) {
    // const url = "https://your-domain.com/api/users/login"; // Adjust URL accordingly When page goes live

    try {
      const response = await fetch("/api/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password: md5(password) }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const user = await response.json();
      console.log("User fetched successfully:", user);
      alert("User fetched successfully:", user);
      return user;
    } catch (error) {
      console.error("Error fetching user:", error);
      return null;
    }
  }

  const handleLogin = async () => {
    setError(false);
    if (credentials.email && credentials.password) {
      fetchUserByEmailAndPassword(credentials.email, credentials.password).then(
        (response) => {
          if (response) {
            // If authentication is successful, set user and login
            dispatch(setUser(response));
            dispatch(login());
            setLocalStorageWithExpiry("userSession", response);

            // Navigate to the portal for 'Store' users or back to the previous page for others
            if (response?.accountType === "Store") {
              navigate("/portal/overview");
            } else {
              navigate(-1);
            }
          } else {
            alert("Handle endpoint Error");
          }
        }
      );
    } else {
      setError(true);
    }
  };

  return (
    <LoginFormContainer>
      <FormLogo />
      {isLoginForm && !orderData ? (
        <FormWrapper>
          <input
            type="text"
            placeholder="Email"
            value={credentials.email}
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
            className={error && !credentials.email ? "error" : ""}
          />
          <input
            type="password"
            placeholder="Password"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            className={error && !credentials.password ? "error" : ""}
          />
          <button onClick={handleLogin}>Login</button>

          {/* <div>Sign in with Facebook/Instagram/Gmail etc</div> */}
        </FormWrapper>
      ) : (
        <CreateAccountForm orderData={orderData} />
      )}
      <div style={{ textAlign: "center" }}>
        <div onClick={() => setIsLoginForm(!isLoginForm)}>
          {isLoginForm && !orderData ? "Create an account" : "Log in"}
        </div>
        <div style={{ marginTop: "10px" }}>
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </div>
    </LoginFormContainer>
  );
};

export default Login;

const LoginFormContainer = styled.div`
  display: grid;
  grid-template-rows: 150px 1fr;
  height: calc(100vh - 48px);
  padding: 24px;
`;

const FormLogo = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url(${orderlyLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const FormWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;

  height: max-content;
  gap: 20px;
  margin-bottom: 50px;

  input {
    width: 100%;
    height: 40px;

    &.error {
      outline: 2px solid red;
    }
  }

  button {
    height: 50px;
    width: 150px;
  }
`;
